import React from "react"
import SEO from "../components/seo"
import Search from "./search"

const NotFoundPage = ({ location }) => (
  <div>
    <SEO title="404 Not found" />
    <h1>Food not found</h1>
    <p>You just hit a route that doesn't exist... search among our other recipes</p>
    <img src="https://media.giphy.com/media/Jk4ZT6R0OEUoM/giphy.gif" alt="animated"></img>
  </div>
)

export default NotFoundPage
