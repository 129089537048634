import React, { Component } from 'react'
import { Link, Img } from "gatsby"
import styles from "../styles/search.module.css"
import {isMobile} from 'react-device-detect';

class SearchBox extends Component {
    constructor(props){
        super(props)
        this.state = {
            query: '',
            typing: false,
            typingtimeout: 0,
        }
    }

    componentDidMount = () => {
        if(window.location.hash.length > 0){
            const query = window.location.hash.substr(1)
            this.props.onChange(query)
            this.setState({
                query: query
            })
        }
    }

    ontype = (event) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            query: event.target.value,
            typing: false,
            typingTimeout: setTimeout(() => {
                if(this.state.query.length >= 3){
                    this.props.onChange(this.state.query)
                }
            }, 1000)
        })
    }

    render = () => {
        return (
            <div className={styles.searchBoxContainer}>
                <input
                    className={styles.inputBox}
                    type="text"
                    onChange={(event) => this.ontype(event)}
                    placeholder={'Search'}
                    value={this.state.query? this.state.query: ''}
                />
            </div>
        )
    }    
}

const getCountFromArray = (array, n) => {
    const countObj = {}
    array.forEach(function(x) {
        if(x.length > 0){
            x = x.split('$').join(' ')
            countObj[x] = (countObj[x] || 0)+1; 
        } 
    });
    const sortedCountList = Object.entries(countObj).sort((a, b) => b[1] - a[1])
    console.log(sortedCountList)
    return sortedCountList.slice(0, n)
}

const SearchDoc = ({doc}) => {
    const sep = doc.category && doc.cuisine? '·': ''
    return (
        <Link to={doc.path}>
            <div className={styles.searchDocContainer} >
                <img src={doc.image} style={{marginBottom: '20px'}}/>
                <div className={styles.searchText}>
                    <h3 className={styles.searchDocHeader}>{doc.title}</h3>
                    <div>
                        <span className={styles.categoryButton}>{doc.category.length > 0? doc.category: ''}</span>
                        {sep}
                        <span className={sep? styles.cuisineButton: 'cuisinenomarg'}>{doc.cuisine.length > 0? doc.cuisine: ''}</span>
                    </div>
                </div>
            </div>
        </Link>
    )
}

const SearchButtons = ({keywords, cuisines, categorys}) => {
    const keywordButtons = keywords.map((kw) => {
        const keywordSlug = kw[0].replace(/ /g, '_').toLowerCase()
        return (
            <Link to={'/collection/' + keywordSlug + '/'}>
                <div className={styles.searchButton}> {kw[0]} </div>
            </Link>
        )
    })

    const cuisineButtons = cuisines.map((cu) => {
        const cuisineSlug = cu[0].replace(/ /g, '_').toLowerCase()
        return (
            <Link to={'/cuisine/' + cuisineSlug + '/'}>
                <div className={styles.searchButton}> {cu[0]} </div>
            </Link>
        )
    })

    const categoryButtons = categorys.map((ca) => {
        const categorySlug = ca[0].replace(/ /g, '_').toLowerCase()
        return (
            <Link to={'/category/' + categorySlug + '/'}>
                <div className={styles.searchButton}> {ca[0]} </div>
            </Link>
        )
    })

    const allButtons = [...categoryButtons, ...keywordButtons, ...cuisineButtons]
    return allButtons


}

class Search extends Component {
    constructor(props){
        super(props)
        this.state = {
            results: [],
            keywords: [],
            cuisines: [],
            categorys: []
        }
    }

    checkIndexLoaded = (query) => {
        const checkFlag = (count) => {
            if((!window.__LUNR__) && (count <= 10)) {
               console.log('timeout')
               window.setTimeout(() => checkFlag(count + 1), 500); /* this checks the flag every 100 milliseconds*/
            } else {
              console.log('calling search')  
              const { results, keywords, cuisines, categorys } = this.getSearchResults(query)
              this.setState({ results: results, keywords: keywords, cuisines: cuisines, categorys: categorys })
            }
        }
        checkFlag(1);
    }

    getSearchResults = (query) => {
        if(!window.__LUNR__){
            console.log('start timeouts')
            this.checkIndexLoaded(query)
            return {'results': [],'keywords': [],'cuisines': [],'categorys': []}
        } else {
            console.log('searching')
            const index = window.__LUNR__['en']['index']
            const store = window.__LUNR__['en']['store']
            const resultsId = index.search(query).slice(0, 30).map(({ref}) => ref)
            const resultsWildId = index.search('*' + query + '*').slice(0, 30).map(({ref}) => ref)
            const resultsIds = [...new Set(resultsId.concat(resultsWildId))]
            const results = resultsIds.map(ref => store[ref])
            const categoryList = getCountFromArray(results.map((r) => r['category']), isMobile? 2: 5)
            const cuisineList = getCountFromArray(results.map((r) => r['cuisine']), isMobile? 2: 5)
            const keywordList = getCountFromArray(results.map((r) => r['keywords'].split('_')).flat(), isMobile? 3: 10)
            return {
                'results': results,
                'keywords': keywordList,
                'cuisines': cuisineList,
                'categorys': categoryList
            }
        }
    }

    search = (userQuery) => {
        const query = userQuery
        window.location.href = window.location.origin + window.location.pathname + '#' + userQuery
        console.log(userQuery)
        if (userQuery.length >= 3){
            const { results, keywords, cuisines, categorys } = this.getSearchResults(query)
            this.setState({ results: results, keywords: keywords, cuisines: cuisines, categorys: categorys })
        } else {
            this.setState({ results: [], keywords: [], cuisines: [], categorys: [] })
        }
    }

    render = () => {
        return (
            <div>
              <SearchBox onChange={this.search}/>
              <div className={isMobile? styles.searchResultsContainer : styles.searchResultsContainerLaptop}>
                <div className={isMobile? styles.searchButtonsMobile: styles.searchButtons}>
                    <SearchButtons 
                        categorys={this.state.categorys}
                        cuisines={this.state.cuisines}
                        keywords={this.state.keywords}
                    /> 
                </div>
                <div className={isMobile? styles.searchResultsMobile: styles.searchResults}>
                    {this.state.results.map((doc, i) => {
                        return <SearchDoc key={i} doc={doc}/>
                    })}
                </div>
              </div>
            </div>
        )
    }
}


export default Search